var jquery = require("jquery");
window.$ = window.jQuery = jquery;
require("particles.js");

var owlCarousel = require("owl.carousel");

function animate({ timing, draw, duration, callback }) {
  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    let progress = timing(timeFraction);

    draw(progress);

    if (timeFraction < 1) requestAnimationFrame(animate);
    else callback();
  });
}

function toggleVisibility(el) {
  let isHidden = getComputedStyle(el)["display"] == "none";
  if (isHidden) el.style.display = "block";

  animate({
    duration: 300,
    timing(timeFraction) {
      return timeFraction;
    },
    draw(progress) {
      el.style.height =
        Math.abs(isHidden ? 160 * progress : 160 - 160 * progress) + "px";
    },
    callback() {
      el.style.display = isHidden ? "block" : "none";
    }
  });
}

function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === "complete"
      : document.readyState !== "loading"
  ) {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

ready(function() {
  setTimeout(function() {
    let preloader = document.querySelector("#preloader");
    let loading = document.querySelector("#loading");
    let homeWrapper = document.querySelector("#home-wrapper");
    let textIntros = document.querySelectorAll(".text-intro");

    preloader.style.opacity = 0;

    animate({
      duration: 1000,
      timing(timeFraction) {
        return Math.pow(timeFraction, 4);
      },
      draw(progress) {
        loading.style.opacity = 1 - 1 * progress;
      },
      callback() {
        loading.style.display = "none";
      }
    });

    homeWrapper.style.opacity = 1;
    setTimeout(function() {
      textIntros.forEach(function(el, i) {
        !(function() {
          setTimeout(function() {
            if (el.classList) el.classList.add("fadeInUp");
            else el.className += " " + "fadeInUp";

            el.style.opacity = 1;
          }, 150 * i + 150);
        })();
      });
    }, 1000);
  }, 1000);

  $(".owl-carousel").owlCarousel({
    center: true,
    nav: false,
    items: 1,
    loop: true,
    dots: false,
    lazyLoad: true,
    autoplay: true,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1
      },
      450: {
        items: 2
      },
      700: {
        items: 3
      }
    }
  });
  

  let navbarToggler = document.querySelector(".navbar-toggler");
  let navbarCollapse = document.querySelector(".navbar-collapse");
  
  navbarToggler.addEventListener("click", function() {
    toggleVisibility(navbarCollapse);
  });

  particlesJS.load("particles-js", "./particles.json", function() {});

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("./js/sw.js");
  }

  var Tawk_API = Tawk_API || {};
    // Tawk_LoadStart = new Date();
  (function() {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5bebb9520e6b3311cb79299f/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
});
